import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage5 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 05</h3>
          <h1>Before The Event</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              <b>Prepare In advance</b>
            </p>

            <p>
              Event preparation begins well before the morning of the event.
              Once event programming is available technical officials should:
            </p>

            <p>
              <ul>
                <li>Review movement standards</li>
                <li>Visualize how the test will flow</li>
                <li>Identify potential areas of concern</li>
                <li>Questions to discuss prior to the event</li>
              </ul>
            </p>

            <p>
              The assigned team of iF3 Technical Officials will have access to
              collaborative websites and documents to prior to an event. This is
              the ideal forum in which to discuss “in-event execution” and
              resolve any outstanding issues.
            </p>

            <p>
              <b>Be a Student of the Sport</b>
            </p>

            <p>
              Effective officiating requires an understanding of the sport of
              functional fitness beyond the basics. Awareness of the broader
              topics and how those may impact performance on the field of play
              will aid in accurate rulings and better understanding of the
              sport.
            </p>

            <p>
              In addition to attending live events and watching televised
              events, Technical Officials should participate in online
              discussions in the various online forums that exist for functional
              fitness events. Through study of actual events and participation
              in professional discussions, a Technical Official can broaden
              perspective and benefit from the observations and experience of
              other functional fitness sport officials.
            </p>

            <p>
              Review your previous judging performances. Self-identification of
              opportunities is critical to continually improving your
              performance. As part of the iF3 Technical Official system,
              performance feedback will be provided for your review and
              analysis.
            </p>

            <p>
              <b>Scout Athlete Performance</b>
            </p>

            <p>
              As in any sport, an athlete’s ability to complete the test as
              prescribed is only a portion of what will occur on the field of
              play. Individual or team strategy may come into play as well.
            </p>

            <p>
              Whenever possible, a Technical Official should use available
              resources to prepare to the fullest. This potentially includes:
            </p>

            <p>
              <ul>
                <li>
                  Watching athletes and teams during practice or in warm up
                </li>
                <li>Social Media videos</li>
              </ul>
            </p>

            <p>
              Technical Officials may not intervene, offer suggestions or
              opinion to athletes or teams on any strategy observed. All
              questions from athletes, coaches, or teams should be referred to
              the Head Technical Official for the event.
            </p>

            <p>
              <b>Time and Place</b>
            </p>

            <p>
              Technical Officials are expected to adhere to the “Time and Place”
              mantra for multiple topics:
            </p>

            <p>
              <ul>
                <li>
                  <b>Event and Heat Start:</b> Event Schedules will be published
                  in advance of each event. Technical Officials will be
                  scheduled throughout the event and are expected to know and
                  follow individual assignments
                </li>
                <li>
                  <b>Movement standards or test questions:</b> Whenever
                  possible, all questions should be addressed prior to arrival
                  at an event
                </li>
                <li>
                  Any issues that arise during the event typically require quick
                  attention for proper resolution. Technical Officials should
                  share their concern or questions with the Head Technical
                  Official in a discrete manner to prevent undue escalation of
                  the situation.
                </li>
              </ul>
            </p>

            <p>
              <b>General Best Practices</b>
            </p>

            <p>
              During the course of an event, it is essential that a Technical
              Official prepares and maintains him / herself for top performance.{" "}
            </p>

            <p>
              <ul>
                <li>
                  <b>Dress appropriately:</b> Including hats, sunglasses, knee
                  braces / pads
                </li>
                <li>
                  <b>Rest:</b> Sit when you are not working an assigned heat
                </li>
                <li>
                  <b>Hydrate:</b> Drink water
                </li>
                <li>
                  <b>Eat:</b> Before during, and after the event. Pack snacks
                </li>
                <li>
                  <b>Stretch:</b> Warm up, cool down. This is a great team
                  activity!
                </li>
              </ul>
            </p>

            <p>
              <b>Social Media</b>
            </p>

            <p>
              <ul>
                <li>
                  Technical Officials are prohibited from soliciting athletes or
                  teams for personal pictures, autographs or souvenirs
                </li>
                <li>
                  Technical Officials are prohibited from using personal social
                  media platforms for 60 minutes prior to the start of an event
                  day until 60 minutes after the event is complete
                </li>
                <li>
                  Technical Official Social Media posts are prohibited from
                  supporting a specific team or athlete
                </li>
                <li>
                  Technical Official Social Media posts are prohibited from
                  commenting on questioned and/or questionable officiating at an
                  event where the Technical Official HAS or HAS NOT served in an
                  officiant capacity
                </li>
              </ul>
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/4">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/6">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage5
